.companies {
    font-size: 27px;
    font-weight: 700;
    line-height: 36px;
    color: #fff;
    margin-bottom: 30px;
}

.companies-table td, .companies-table th {
    border: none !important;
    text-align: center;
    vertical-align: middle !important;
}

.companies-table th {
    font-weight: normal;
    font-size: 9px;
    line-height: 13px;

    color: #FFFFFF;

    padding-top: 11px !important;
    padding-bottom: 11px !important;
}

.companies-table td {
    font-weight: normal;
    font-size: 9px;
    line-height: 14px;

    color: #B8D9FF;
}

.companies-table {
    margin-bottom: 50px !important;
}



/* tablet */
@media (min-width: 768px) {
    .companies {
        font-size: 39px;
        line-height: 59px;
        margin-bottom: 56px;
    }

    .companies-table th {
        font-size: 15px;
        line-height: 22px;
        padding-top: 14px !important;
        padding-bottom: 14px !important;
    }

    .companies-table td:not([class^="row-divider"]) {
        font-size: 16px;
        line-height: 22px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .companies-table {
        margin-bottom: 92px !important;
    }
}

/* laptop */
@media (min-width: 992px) {
    .companies {
        margin-bottom: 67px;
    }

    .companies-table th {
        font-size: 18px;
        line-height: 27px;
        padding-top: 17px !important;
        padding-bottom: 17px !important;
    }

    .companies-table td:not([class^="row-divider"]) {
        font-size: 18px;
        line-height: 27px;
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .table-row {
        cursor: initial;
    }

    .companies-table {
        margin-bottom: 74px !important;
    }
}
