.header-logo__text {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0;
    text-align: left;
    color: #FFFFFF;
}

.header__text-small {
    font-size: 12px;
}

.header__text-big {
    font-size: 18px;
    line-height: 27px;
}

.header__link {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
    color: #B8D9FF !important;
    padding: 24px 0 24px 35px !important;
}

.header-logo-container {
    display: flex;
    padding-bottom: 36px;
}

.logo-big {
    padding-left: 28px;
    padding-right: 23px;
}

.logo-small {
    padding-right: 8px;
}

@media (max-width: 768px) {
    .header__navbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
