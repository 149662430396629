.footer__title {
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
    color: #A4CAE5;
}

.footer__description {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #B8D9FF;
    opacity: 0.5;
}

.divider {
    margin: 0 16px;
    height: 1px;
    background-color: #2F3954;
}

.footer__divider-container {
    margin-bottom: 32px;
}

.footer__icons-container {
    justify-content: center;
    margin-bottom: 24px;
}

.footer__title-container {
    justify-content: center;
    margin-bottom: 25px;
}

.footer__warning-icon-container {
    justify-content: center;
    margin-bottom: 16px;
}

.footer__description-container {
    justify-content: center;
    padding-bottom: 4px;
}

/* tablet */
@media (min-width: 768px) {
    .footer__divider-container {
        margin-bottom: 42px;
    }

    .footer__icons-container {
        margin-bottom: 42px;
    }

    .footer__icons-container {
        margin-bottom: 45px;
    }

    .footer__description-container {
        padding-bottom: 46px;
    }

    .footer__description {
        font-size: 13px;
        line-height: 24px;
        max-width: 630px;
    }
}

/* laptop */
@media (min-width: 992px) {
    .footer__divider-container {
        margin-bottom: 112px;
    }

    .footer__icons-container {
        margin-bottom: 44px;
    }

    .footer__description-container {
        padding-bottom: 164px;
    }

    .footer__description {
        font-size: 14px;
        max-width: 755px;
    }
}
