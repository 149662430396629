.company {
    margin-bottom: 70px;
}

.description__switcher {
    display: flex;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    text-align: left;

    color: #128DFF;
}

.description__switcher span {
    margin-right: 9px;
}

.description__switcher_up {
    transform: rotate(90deg);
}

.description__switcher_down {
    transform: rotate(-90deg);
}

.info__row {
    margin-bottom: 6px;
}

.info__col-left {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;

    color: #B8D9FF;
}

.info__col-right {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;

    color: #B8D9FF;
}

.info__row-divider {
    height: 1px;
    background: linear-gradient(72.96deg, #BCDBFD -1.59%, #FEA613 15.21%, #FF3F3A 29.49%, #FF033C 46.71%, #B11E82 63.08%, #473C76 79.04%);
}

.info__website {
    color: #128DFF;
}

.company__description {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 25px;

    color: #B8D9FF;
}

@media (max-width: 767px) {
    .company__info {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

        padding: 13px 10px;

        color: #B8D9FF;

        background: linear-gradient(0, #08080B 0%, #13131B 100%);
        border-radius: 9px;
    }

    .company__description {
        padding: 13px 10px;
        background: linear-gradient(0, #08080B 0%, #13131B 100%);
        border-radius: 9px;
    }
}

/* tablet */
@media (min-width: 768px) {
    .platforms__description {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 33px;
    }

    .platforms {
        margin-bottom: 73px;
    }

    .info__row {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .info__col-left {
        font-size: 18px;
        line-height: 30px;
    }

    .info__col-right {
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
    }

    .company__info {
        margin-bottom: 52px;
    }

    .company__description {
        font-size: 18px;
        line-height: 27px;
        color: #B8D9FF;
    }
}

/* laptop */
@media (min-width: 992px) {
    .platforms__description {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 77px;
    }

    .platforms {
        margin-bottom: 145px;
    }
}
