.heading {
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin-bottom: 25px;
    color: #FFFFFF;
}

/* tablet */
@media (min-width: 768px) {
    .heading {
        font-size: 39px;
        line-height: 59px;
    }
}
