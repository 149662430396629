.modal-content {
    background: linear-gradient(0, #08080B 0%, #13131B 100%);
    background-color: unset !important;
    border-radius: 24px !important;
    border: none !important;
}

.modal-header {
    border-bottom: none !important;
    padding: 58px 52px !important;
}

.modal-header .close {
    position: absolute !important;
    top: 20px !important;
    right: 20px !important;
    color: #128DFF !important;
    text-shadow: 0 1px 0 #128DFF !important;
}

.modal-title {
    display: flex;
    font-style: normal;
    font-weight: bold !important;
    font-size: 29px;
    line-height: 48px;

    color: #FFFFFF;
}

.modal-body {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;

    color: #B8D9FF;

    padding: 0 52px !important;
}

.modal-footer {
    border-top: none !important;
    padding: 58px 52px !important;
}

.modal-backdrop.show {
    opacity: 0.85 !important;
}

.modal__understand {
    font-style: normal;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 27px !important;

    color: #128DFF !important;
    border-color: #128DFF !important;
}

.modal__understand:hover {
    color: #fff !important;
}

@media (min-width: 768px) {
    .modal-title {
        font-size: 39px !important;
        line-height: 58px !important;
    }

    .modal-body {
        font-size: 18px !important;
        line-height: 32px !important;
    }
}
