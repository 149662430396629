.about {
    margin-top: 12px;
}

.heading {
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin-bottom: 25px;
    color: #FFFFFF;
}

.about_description {
    padding-bottom: 46px;
}

.about__text {
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    color: #B8D9FF;
}

.about__link {
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0;
    color: #128DFF;
}

/* tablet */
@media (min-width: 768px) {
    .about {
        margin-top: 16px;
    }

    .heading {
        font-size: 39px;
        line-height: 59px;
    }

    .about_description {
        padding-bottom: 65px;
    }
}

/* laptop */
@media (min-width: 1024px) {
    .about {
        margin-top: 61px;
    }

    .about__text {
        max-width: 676px;
    }

    .about_description {
        padding-bottom: 125px;
    }
}
