.team-member {
    padding-bottom: 40px;
}

.team-member__photo {
    width: 100%;
    height: auto;
}

.team-member__name {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;

    color: #FFFFFF;

    padding-top: 4px;
}

.team-member__position {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;

    color: #FFFFFF;

    padding-top: 8px;
}

.team-member__description {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;

    color: #B8D9FF;

    padding-top: 8px;
    padding-bottom: 20px;
}

@media (min-width: 768px) {
    .team-member__name {
        font-size: 21px;
        line-height: 31px;
    }

    .team-member__position {
        font-size: 21px;
        line-height: 31px;
    }

    .team-member__description {
        font-size: 18px;
        line-height: 32px;
    }
}

@media (min-width: 1024px) {
    .team-member {
        padding-bottom: 80px;
    }
}