.partners {
    margin-top: 23px;
}

.partner {
    margin-bottom: 38px;
}

.partner__logo {
    margin-bottom: 15px;
}

@media (min-width: 768px) {
    .partners {
        margin-top: 33px;
    }

    .partner {
        margin-bottom: 43px;
    }

    .partner__logo {
        margin-bottom: 0;
    }
}

@media (min-width: 1024px) {
    .partners {
        margin-top: 73px;
    }

    .partner__logo {
        height: 56px;
        width: auto;
        margin-bottom: 39px;
    }
}