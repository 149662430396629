* {
    font-family: Poppins, sans-serif;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
}

.about__background {
    background: url("https://assets.ambisafe.io/unicorn-background-logo.svg");
    background-repeat: no-repeat;
    background-size: 125% auto;
    background-position: 35px 1%;

    position: absolute;
    height: 100%;
    width: 100%;
}

.for-platforms__background {
    background: url("https://assets.ambisafe.io/unicorn-background-logo.svg");
    background-repeat: no-repeat;
    background-size: 125% auto;
    background-position: 48px 5%;

    position: absolute;
    height: 100%;
    width: 100%;
}

@media (min-width: 768px) {
    .about__background {
        background-size: 85% auto;
        background-position: 100px -3%;
    }

    .for-platforms__background {
        background-size: 125% auto;
        background-position: 66px -20%;
    }
}

@media (min-width: 1024px) {
    .about__background {
        background-size: 70% auto;
        background-position: right top;
        margin-top: -60px;
    }

    .for-platforms__background {
        background-size: 80% auto;
        background-position: 150% 10%;
    }
}

@media (min-width: 1440px) {
    .for-platforms__background {
        background-size: 65% auto;
        background-position: 84% 7%;
    }
}
