.title {
    margin-bottom: 62px;
}

.asset-icon__icon {
    width: 96px;
    height: 96px;
}

.description {
}

.description__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #B8D9FF;
}

.logo {
    max-width: 100%;
    height: auto;
    margin-top: 45px;
}

/* tablet */
@media (min-width: 768px) {
    .description__text {
        font-size: 18px;
        line-height: 28px;
    }

    .title {
        margin-bottom: 73px;
    }

    .asset-icon__icon {
        width: 81px;
        height: 81px;
    }

    .logo {
        margin-top: 0;
        max-width: 180px;
    }
}

/* laptop */
@media (min-width: 992px) {
    .description__text {
        font-size: 24px;
        line-height: 36px;
    }

    .title {
        margin-bottom: 145px;
    }

    .asset-icon {
        max-width: 430px;
    }

    .asset-icon__icon {
        width: 138px;
        height: 138px;
    }

    .description {
        max-width: 430px !important;
    }

    .company-logo {
        flex: 1 0 0 !important;
        max-width: 100% !important;
        background: radial-gradient(50% 50% at 50% 50%, #212227 0%, #000000 100%);
    }

    .logo {
        max-width: 470px;
    }
}
