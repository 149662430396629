.home {
    max-width: 1300px !important;
}

.home__container {
    display: flex;
    flex-direction: column;
}

.home__heading {
    font-size: 27px;
    font-weight: 700;
    line-height: 36px;
    padding-top: 12px;
    color: #fff;
}

.home__image {
    width: 100%;
    height: auto;
    position: relative;
    top: -39px;
}

.home__description {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #B8D9FF;
}

.home__description-mobile {
    padding-bottom: 55px;
}

.home__link {
    padding-left: 15px;
}

.home__benefits {
    font-size: 27px;
    font-weight: 700;
    line-height: 36px;
    color: #fff;
    padding-bottom: 41px;
}

.home__items {
    display: flex;
    flex-direction: column;
    padding-bottom: 47px;
}

.item {
    height: 100%;
}

/* tablet */
@media (min-width: 768px) {
    .home__container {
        flex-direction: row-reverse;
        padding-bottom: 57px;
    }

    .home__image {
        width: 50%;
        padding-right: 60px;
        top: -10px;
    }

    .home__heading {
        font-size: 39px;
        font-weight: 700;
        line-height: 59px;
        text-align: left;
        padding-top: 60px;
    }

    .home__benefits {
        font-size: 39px;
        padding-bottom: 32px;
    }

    .home__link {
        padding-left: 0;
    }

    .home__items {
        padding-bottom: 24px;
    }
}

/* laptop */
@media (min-width: 992px) {
    .home__container {
        padding-bottom: 110px;
    }

    .home__image {
        padding-right: 80px;
    }

    .home__heading {
        width: 425px;
    }

    .home__description {
        width: 370px;
    }

    .home__benefits {
        padding-bottom: 70px;
    }

    .home__items {
        flex-direction: row;
    }

    .home__items {
        padding-bottom: 127px;
    }
}
