.platforms {
    margin-bottom: 70px;
}

.platforms__description {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 25px;

    color: #B8D9FF;
}

.platforms__image {
    width: 100%;
    height: 133px;
    margin-bottom: 10px;
}

.orderbook {
    background: radial-gradient(100.34% 155.65% at 50% 132.14%, rgba(250, 255, 19, 0.5) 0%, rgba(215, 82, 114, 0.5) 57.81%, rgba(33, 29, 255, 0.5) 100%);
    border-radius: 18px;
}

.orderbook:hover {
    background: radial-gradient(100.34% 155.65% at 50% 132.14%, #FAFF13 0%, #D75272 57.81%, #211DFF 100%);
}

.raison {
    background: radial-gradient(98.61% 152.98% at 50% 152.98%, rgba(0, 13, 84, 0.5) 0%, rgba(112, 80, 203, 0.5) 57.81%, rgba(255, 0, 0, 0.5) 100%);
    border-radius: 18px;
}

.raison:hover {
    background: radial-gradient(98.61% 152.98% at 50% 152.98%, #000D54 0%, #7050CB 57.81%, #FF0000 100%);
}

.roobee {
    background: radial-gradient(105.13% 163.1% at 50% 152.98%, rgba(0, 145, 23, 0.5) 0%, rgba(29, 70, 214, 0.5) 57.81%, rgba(98, 0, 47, 0.5) 100%);
    border-radius: 18px;
}

.roobee:hover {
    background: radial-gradient(105.13% 163.1% at 50% 152.98%, #4CD361 0%, #1D46D6 57.81%, #EA0070 100%);
}

/* tablet */
@media (min-width: 768px) {
    .platforms__description {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 33px;
    }

    .platforms {
        margin-bottom: 73px;
    }
}

/* laptop */
@media (min-width: 992px) {
    .platforms__description {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 77px;
    }

    .platforms {
        margin-bottom: 145px;
    }
}
