.item {
    padding-top: 16px;
}

.item__container {
    display: flex;
}

.item__heading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item__heading {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    color: #fff;
    margin: 0;
}

.item__description {
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #B8D9FF;

    padding-top: 20px;
}

@media (min-width: 768px) {
    .item {
        background: linear-gradient(0, #08080B 0%, #13131B 100%);
        border-radius: 24px;
        padding: 23px 25px;
        margin-bottom: 20px;
    }
}