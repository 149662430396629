.table-row {
    cursor: pointer;
}

.custom-link {
    color: inherit;
    line-height: inherit;
    font-size: inherit;
}

.custom-link:hover {
    text-decoration: none;
    color: inherit;
}

/* laptop */
@media (min-width: 992px) {
    .table-row {
        cursor: initial;
    }
}
