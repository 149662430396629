.for-platforms__documentation {
    padding-bottom: 46px;
}

.heading {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 36px;

    color: #FFFFFF;

    position: relative;
    z-index: 2;
}

.documentation__document {
    padding-bottom: 45px;
}

.document__label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;

    display: flex;
    align-items: center;
    letter-spacing: -0.4px;

    color: #FFFFFF;
}

@media (min-width: 768px) {
    .heading {
        font-size: 39px;
        line-height: 57px;
    }

    .documentation__document {
        padding: 18px 27px !important;
        margin-bottom: 20px;
        background: linear-gradient(0, #08080B 0%, #13131B 100%);
        border-radius: 12px;
    }
}

@media (min-width: 1024px) {
    .heading {
        padding-bottom: 67px;
    }

    .documentation__document {
        padding: 40px 38px !important;
    }
}
