.offering {
    margin-bottom: 60px;
}

.offering__body {
    background: linear-gradient(0, #08080B 0%, #13131B 100%);
    border-radius: 9px;
    padding: 13px 10px 30px 10px;
}

.offering__row {
    margin-bottom: 6px;
}

.offering__col-left {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;

    color: #B8D9FF;
}

.offering__col-right {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;

    color: #B8D9FF;
}

.offering__row-divider {
    height: 1px;
    background: linear-gradient(72.96deg, #BCDBFD -1.59%, #FEA613 15.21%, #FF3F3A 29.49%, #FF033C 46.71%, #B11E82 63.08%, #473C76 79.04%);
}

.offering__address {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;

    color: #128DFF;
}

.big-padding {
    height: 30px;
}

.small-padding {
    height: 15px;
}

/* tablet */
@media (min-width: 768px) {
    .offering__body {
        background: none;
    }

    .offering__row {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .offering__col-left {
        font-size: 18px;
        line-height: 30px;
    }

    .offering__col-right {
        font-size: 18px;
        line-height: 30px;
        font-weight: normal;
    }
}

/* laptop */
@media (min-width: 992px) {

}
