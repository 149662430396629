.for-platforms__partners {
    padding-top: 12px;
    padding-bottom: 32px;
}

.heading {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 36px;

    color: #FFFFFF;

    position: relative;
    z-index: 2;
}

.partners__logo-container {
    height: 94px;
    margin-right: -15px;
}

.partners__logo {
    width: 100px;
    height: auto;
    position: relative;
    top: -90px;
    z-index: 1;
}

.partners__question {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;

    color: #B8D9FF;
}

.partners__offering {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;

    color: #B8D9FF;

    max-width: 767px;
}

.partners__contacts-us {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;

    color: #B8D9FF;
}

@media (min-width: 768px) {
    .for-platforms__partners {
        padding-top: 36px;
        padding-bottom: 76px;
    }

    .heading {
        font-size: 39px;
        line-height: 57px;
    }

    .partners__logo {
        width: 235px;
    }

    .partners__question {
        font-size: 18px;
        line-height: 27px;
    }

    .partners__offering {
        font-size: 18px;
        line-height: 27px;

        padding-bottom: 28px;
    }

    .partners__contacts-us {
        font-size: 18px;
        line-height: 27px;
    }
}

@media (min-width: 1024px) {
    .for-platforms__partners {
        padding-top: 64px;
        padding-bottom: 142px;
    }

    .partners__logo {
        width: 255px;
        height: auto;
        position: relative;
        top: -145px;
    }
}
